import { FunctionComponent } from 'react'
import React from 'react'
import Layout from '../components/layout/layout'
import { Site, MarkdownRemarkConnection } from '../graphql-types'
import { graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Card } from '../components/card'

const Styles = require('../styles/pages/series.module.styl')

type Props = {
  data: {
    site: Site
    allMarkdownRemark: MarkdownRemarkConnection
  }
  location: Location
}

type Category = {
  title?: string
  description?: string
  count: number
  timeToRead: number
}

type SeriesProps = {
  [key: string]: Category
}

const Series: FunctionComponent<Props> = ({ location, data: { site, allMarkdownRemark } }) => {
  const colors = ['#FFE3EB', '#F7F6B7', '#DBF7B7', '#CDF7F2', '#E4DAF7']
  const series: SeriesProps = allMarkdownRemark.edges.reduce((pre, cur) => {
    const path = cur.node.fileAbsolutePath!
    const timeToRead = cur.node.timeToRead!
    const title = cur.node.frontmatter?.title!
    const description = cur.node.frontmatter?.description!
    const category = path.substring(path.search('/series/')).split('/')[2]
    if (!pre[category]) pre[category] = {} as Category
    // 每个系列的 readme 包含对这个系列的简介
    if (path.includes('readme')) {
      pre[category] = { ...pre[category], title, description }
    }
    if (!path.includes('readme')) {
      pre[category]['count'] ? pre[category]['count']++ : (pre[category]['count'] = 1)
    }
    pre[category]['timeToRead'] ? (pre[category]['timeToRead'] += timeToRead) : (pre[category]['timeToRead'] = timeToRead)
    return pre
  }, {} as SeriesProps)

  return (
    <Layout>
      <Card cardCls={Styles['card']}>
        <div className={Styles['header']}> Series </div>
        <div className={Styles['line']} />
      </Card>
      <div className={Styles['book_wrap']}>
        {Object.keys(series).map(key => (
          <Link key={key} to={`/series/${key}`} className={Styles['book_item']}>
            <div style={{ backgroundColor: colors[Math.ceil(Math.random() * (colors.length - 1))] }} className={Styles['book_cover']} />
            <div className={Styles['book_content']}>
              <div className={Styles['book_title']}>{series[key].title}</div>
              <div className={Styles['book_des']}>
                <ReactMarkdown source={series[key].description} />
              </div>
              <div className={Styles['book_info']}>
                共 {series[key].count} 篇系列文章 · 推荐阅读时长 {series[key].timeToRead} 分
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Layout>
  )
}

export default Series

export const query = graphql`
  query BookQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/series/" } }) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD-HH")
            description
          }
          timeToRead
        }
      }
    }
  }
`
